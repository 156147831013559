<template>
  <form>
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("username", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="username"
            v-model="createData.username"
            class="form-control"
            :placeholder="t('username', {}, { locale: getLocal })"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("password", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="password"
            v-model="createData.password"
            class="form-control"
            :placeholder="t('password', {}, { locale: getLocal })"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("name", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="name"
            v-model="createData.name"
            class="form-control"
            :placeholder="t('name', {}, { locale: getLocal })"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("mobile", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="mobile"
            v-model="createData.mobile"
            class="form-control"
            :placeholder="t('mobile', {}, { locale: getLocal })"
          />
        </div>
      </div>
    </div>

    <div class="button-group d-flex pt-25">
      <button
        @click="sendCreateData($event)"
        class="btn btn-primary btn-default btn-squared text-capitalize"
      >
        {{ t("adduser", {}, { locale: getLocal }) }}
      </button>
    </div>
  </form>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "FormAddUserList",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data: function () {
    return {};
  },
  props: ["options", "createData"],
  computed: {
    getLocal: function () {
      return this.$store.getters["display/getLocal"];
    },
  },
};
</script>

<style>
</style>