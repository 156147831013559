<template>
  <div
    :class="`${
      $store.getters['display/getAside'] ? 'contents expanded' : 'contents'
    }`"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <Breadcrumb :options="breadcrumbOptions" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 mb-30">
          <div class="card">
            <!-- ----------------------- header card ---------------------- -->
            <div class="my-card-header color-dark fw-500 p-3">
              <div class="row">
                <!-- --------------------- -->
                <div class="col-12 col-lg-1 col-xl-2 	d-none d-xl-block">
                  <div class="float-left py-2">
                    <p class="h4 px-3">
                      {{ t("filter", {}, { locale: getLocal }) }}
                    </p>
                  </div>
                </div>
                <!-- -------------------------- -->
                <div class="col-12 col-lg col-xl">
                  <div class="row ">
                    <div class="col-12 col-md-6 col-lg-3">
                      <div class="form-group row w-100">
                        <label class="col-5 col-form-label" for="Order">{{
                          t("orderby", {}, { locale: getLocal })
                        }}</label>
                        <div class="col my-dropdown">
                          <select v-model="order" name="Order" class="">
                            <option value="createdAt">
                              {{ t("creat", {}, { locale: getLocal }) }}
                            </option>
                            <option value="drawDate">
                              {{ t("Ddate", {}, { locale: getLocal }) }}
                            </option>
                          </select>
                        </div>
                      </div>
                     
                    </div>
                    <div class="col-12 col-md-6 col-lg-2">
                      <div class="form-group row w-100">
                        <label class="col-5 col-form-label" for="dir">{{
                          t("dir", {}, { locale: getLocal })
                        }}</label>
                        <div class="col my-dropdown">
                          <select v-model="dir" name="dir" class="">
                            <option value="ASC">
                              {{ t("asc", {}, { locale: getLocal }) }}
                            </option>
                            <option value="DESC">
                              {{ t("desc", {}, { locale: getLocal }) }}
                            </option>
                          </select>
                        </div>
                      </div>

                
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                      <div class="form-group row w-100">
                        <label class="col-5 col-form-label" for="status">{{
                          t("status", {}, { locale: getLocal })
                        }}</label>
                        <div class="col my-dropdown">
                          <select v-model="status" name="status" class="">
                            <option value="">
                              {{ t("all", {}, { locale: getLocal }) }}
                            </option>
                            <option value="staging">
                              {{ t("staging", {}, { locale: getLocal }) }}
                            </option>
                            <option value="on_going">
                              {{ t("ongoing", {}, { locale: getLocal }) }}
                            </option>
                            <option value="finish">
                              {{ t("finish", {}, { locale: getLocal }) }}
                            </option>
                          </select>
                        </div>
                      </div>

                    
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                      <div class="form-group row w-100">
                        <label class="col-6 col-form-label" for="drawDate ">{{
                          t("drawdate", {}, { locale: getLocal })
                        }}</label>
                        <div class="col-5 my-dropdown m-0 px-0">
                          <input
                            class="m-0 px-0"
                            v-model="drawDate"
                            type="date"
                          />
                        </div>
                      </div>

                
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ----------------------- end header card ---------------------- -->
            <!-- ----------------------- body card ---------------------- -->
            <div class="card-body m-0 p-0">
              <div class="userDatatable global-shadow border-0 bg-white w-100">
                <!-- ------------------------------------ d-lg-block------------------------- -->
                <div class="table-responsive 	d-none d-xl-block">
                  <table class="table mb-0 table-borderless">
                    <!-- --------------- head table ----------------- -->
                    <thead>
                      <tr class="userDatatable-header">
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("id", {}, { locale: getLocal }) }}
                          </p>
                        </th>

                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("Ddate", {}, { locale: getLocal }) }}
                          </p>
                        </th>

                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("qty", {}, { locale: getLocal }) }}
                          </p>
                        </th>

                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("soldqty", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("wonqty", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("prizemoney", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("image", {}, { locale: getLocal }) }}
                          </p>
                        </th>

                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("stt", {}, { locale: getLocal }) }}
                          </p>
                        </th>

                        <!-- <th >
                          <div class="row">
                            <div class="col-12   col-xxl-6">
                              <p class="userDatatable-title text-center m-0">
                                {{ t("creat", {}, { locale: getLocal }) }}
                              </p>
                            </div>
                            <div class="col-12    col-xxl-6">
                              <p class="userDatatable-title text-center m-0">
                                {{ t("upat", {}, { locale: getLocal }) }}
                              </p>
                            </div>
                          </div>
                        </th> -->
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("creat", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("upat", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("action", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("edit", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <!-- ---------------end head table ----------------- -->
                    <!-- --------------- body table ----------------- -->
                    <tbody id="table_tbody">
                      <tr v-for="(e, index) in listData" :key="index">
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.id ? e.id : "-" }}
                          </div>
                        </td>

                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.drawDate ? e.drawDate : "-" }}
                          </div>
                        </td>

                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.qty ? e.qty : "-" }}
                          </div>
                        </td>

                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.soldQty ? e.soldQty : "-" }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.wonQty ? e.wonQty : "-" }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.prizeMoney ? e.prizeMoney : "-" }}
                          </div>
                        </td>
                        <td>
                          <div
                            @click="showIMG(e)"
                            class="d-flex justify-content-center"
                          >
                            <img
                              v-if="e.backgroundUrl"
                              :src="e.backgroundUrl"
                              alt="bg"
                              class="img-size"
                            />
                            <div
                              v-else
                              class="userDatatable-content text-center"
                            >
                              -
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.status ? e.status : "-" }}
                          </div>
                        </td>

                        <!-- <td>
                          <div class="row">
                            <div class="col-12 col-xxl-6">
                              <div class="userDatatable-content text-center">
                                {{ e.created_at ? e.created_at : "-" }}
                              </div>
                            </div>
                            <div class="col-12 col-xxl-6">
                              <div class="userDatatable-content text-center">
                                {{ e.updated_at ? e.updated_at : "-" }}
                              </div>
                            </div>
                          </div>
                        </td> -->

                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.created_at ? e.created_at : "-" }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.updated_at ? e.updated_at : "-" }}
                          </div>
                        </td>

                        <td>
                          <a
                            v-if="$store.getters['auth/getUser']"
                            href="#"
                            @click="SwalselectStatusStaging(e, 'Staging')"
                            class="btn-action btn-action-close"
                            >{{ t("staging", {}, { locale: getLocal }) }}</a
                          >

                          <a
                            v-if="$store.getters['auth/getUser']"
                            href="#"
                            @click="SwalselectStatusOn(e, 'On Going')"
                            class="btn-action btn-action-cancel"
                            >{{ t("ongoing", {}, { locale: getLocal }) }}</a
                          >

                          <a
                            v-if="$store.getters['auth/getUser']"
                            href="#"
                            @click="SwalselectStatusFinish(e, 'Finish')"
                            class="btn-action btn-action-delete"
                            >{{ t("finish", {}, { locale: getLocal }) }}</a
                          >
                        </td>
                        <td>
                          <a v-if="$store.getters['auth/getUser']">
                            <a href="#" @click="editForm(e)" class="edit">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-edit"
                              >
                                <path
                                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                ></path>
                                <path
                                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                ></path></svg
                            ></a>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <!-- ---------------end body table ----------------- -->
                  </table>
                </div>
                <!-- ------------------------------------end d-lg-block------------------------- -->
                <!-- ----------------------- size md -------------------- -->
                <div class="row w-100 d-none d-md-block d-lg-block d-xl-none">
                  <div class="col-12 mymd">
                    <table class="table table-responsive mb-0 table-borderless">
                      <!-- ------------------ head ------------------- -->
                      <thead>
                        <tr class="userDatatable-header">
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("id", {}, { locale: getLocal }) }}
                            </p>
                          </th>

                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("Ddate", {}, { locale: getLocal }) }}
                            </p>
                          </th>

                          <!-- <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("qty", {}, { locale: getLocal }) }}
                            </p>
                          </th> -->

                          <!-- <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("soldqty", {}, { locale: getLocal }) }}
                            </p>
                          </th> -->
                          <!-- <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("wonqty", {}, { locale: getLocal }) }}
                            </p>
                          </th> -->
                          <!-- <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("prizemoney", {}, { locale: getLocal }) }}
                            </p>
                          </th> -->
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("image", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("stt", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <div class="row">
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("creat", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("upat", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                            </div>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("action", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("edit", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <!-- ----------------- body ------------------- -->
                      <tbody id="table_tbody">
                        <tr
                          class="border_bottom"
                          v-for="(e, index) in listData"
                          :key="index"
                        >
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.id ? e.id : "-" }}
                            </div>
                          </td>

                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.drawDate ? e.drawDate : "-" }}
                            </div>
                          </td>

                          <!-- <td>
                            <div class="userDatatable-content text-center">
                              {{ e.qty ? e.qty : "-" }}
                            </div>
                          </td> -->

                          <!-- <td>
                            <div class="userDatatable-content text-center">
                              {{ e.soldQty ? e.soldQty : "-" }}
                            </div>
                          </td> -->
                          <!-- <td>
                            <div class="userDatatable-content text-center">
                              {{ e.wonQty ? e.wonQty : "-" }}
                            </div>
                          </td> -->
                          <!-- <td>
                            <div class="userDatatable-content text-center">
                              {{ e.prizeMoney ? e.prizeMoney : "-" }}
                            </div>
                          </td> -->
                          <td>
                            <div
                              @click="showIMG(e)"
                              class="d-flex justify-content-center"
                            >
                              <img
                                v-if="e.backgroundUrl"
                                :src="e.backgroundUrl"
                                alt="bg"
                                class="img-size"
                              />
                              <div
                                v-else
                                class="userDatatable-content text-center"
                              >
                                -
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.status ? e.status : "-" }}
                            </div>
                          </td>

                          <td>
                            <div class="row">
                              <div class="col-12 mb-3">
                                <div class="userDatatable-content text-center">
                                  {{ e.created_at ? e.created_at : "-" }}
                                </div>
                              </div>
                              <div class="col-12 mb-3">
                                <div class="userDatatable-content text-center">
                                  {{ e.updated_at ? e.updated_at : "-" }}
                                </div>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div class="row">
                              <div class="col-12 mb-1">
                                <div
                                  v-if="$store.getters['auth/getUser']"
                                  href="#"
                                  @click="SwalselectStatusStaging(e, 'Staging')"
                                  class="btn-action btn-action-close"
                                >
                                  {{ t("staging", {}, { locale: getLocal }) }}
                                </div>
                              </div>
                              <div class="col-12 mb-1">
                                <div
                                  v-if="$store.getters['auth/getUser']"
                                  href="#"
                                  @click="SwalselectStatusOn(e, 'On Going')"
                                  class="btn-action btn-action-cancel"
                                >
                                  {{ t("ongoing", {}, { locale: getLocal }) }}
                                </div>
                              </div>
                              <div class="col-12 mb-1">
                                <div
                                  v-if="$store.getters['auth/getUser']"
                                  href="#"
                                  @click="SwalselectStatusFinish(e, 'Finish')"
                                  class="btn-action btn-action-delete"
                                >
                                  {{ t("finish", {}, { locale: getLocal }) }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a v-if="$store.getters['auth/getUser']">
                              <a href="#" @click="editForm(e)" class="edit">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-edit"
                                >
                                  <path
                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                  ></path>
                                  <path
                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                  ></path></svg
                              ></a>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- -----------------------end size md -------------------- -->
                <!-- ----------------------- size sm -------------------- -->
                <div class="row w-100   d-block d-sm-block d-md-none d-lg-none m-0 p-0 fontsm">
                  <div class="col-12 mymd w-100 m-0 p-0 ">
                    <table
                      class="table mb-0 mb-3"
                      v-for="(e, index) in listData"
                      :key="index"
                    >
                      <thead>
                        <tr class="userDatatable-header">
                          <th></th>
                        </tr>
                      </thead>

                      <tbody id="table_tbody">
                        <tr class="border_bottom">
                          <td class="p-0 m-0">
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("id", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 border p-3">
                                <div class="userDatatable-content text-center">
                                  {{ e.id ? e.id : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("Ddate", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.drawDate ? e.drawDate : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("image", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div
                                  @click="showIMG(e)"
                                  class="d-flex justify-content-center"
                                >
                                  <img
                                    v-if="e.backgroundUrl"
                                    :src="e.backgroundUrl"
                                    alt="bg"
                                    class="img-size"
                                  />
                                  <div
                                    v-else
                                    class="userDatatable-content text-center"
                                  >
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("stt", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.status ? e.status : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0 border">
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                        userDatatable-title
                                        text-center
                                        m-0
                                      "
                                    >
                                      {{ t("creat", {}, { locale: getLocal }) }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.created_at ? e.created_at : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                        userDatatable-title
                                        text-center
                                        m-0
                                      "
                                    >
                                      {{ t("upat", {}, { locale: getLocal }) }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.updated_at ? e.updated_at : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0 text-center">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("action", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 m-0 p-0 border">
                                <div class="row my-2">
                                  <div class="col-12 mb-1">
                                    <button
                                      v-if="$store.getters['auth/getUser']"
                                      href="#"
                                      @click="
                                        SwalselectStatusStaging(e, 'Staging')
                                      "
                                      class="btn-action btn-action-close"
                                    >
                                      {{
                                        t("staging", {}, { locale: getLocal })
                                      }}
                                    </button>
                                  </div>
                                  <div class="col-12 mb-1">
                                    <button
                                      v-if="$store.getters['auth/getUser']"
                                      href="#"
                                      @click="SwalselectStatusOn(e, 'On Going')"
                                      class="btn-action btn-action-cancel"
                                    >
                                      {{
                                        t("ongoing", {}, { locale: getLocal })
                                      }}
                                    </button>
                                  </div>
                                  <div class="col-12 mb-1">
                                    <button
                                      v-if="$store.getters['auth/getUser']"
                                      href="#"
                                      @click="
                                        SwalselectStatusFinish(e, 'Finish')
                                      "
                                      class="btn-action btn-action-delete"
                                    >
                                      {{
                                        t("finish", {}, { locale: getLocal })
                                      }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- -----------------------end size sm -------------------- -->
                <Pagination
                  ref="PaginationRef"
                  :Count="count"
                  @PaginationReturnData="PaginationReturnData"
                />
              </div>
            </div>
            <!-- ----------------------- end body card ---------------------- -->
          </div>
        </div>
      </div>
    </div>
    <ModalImg :srcImage="showIMGData" />
    <ModalAdd :createData="createData" :options="addOptions" />
    <ModalEdit :editData="editData" :options="editOptions" /> 
  </div>
</template>

<script>
// @ is an alias to /src

import * as serviceAPI from "../services/API.service";
import * as serviceMain from "../services/main.service";
import * as constant from "../services/constant";
import Pagination from "@/components/Pagination.vue";
import { useI18n } from "vue-i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ModalImg from "@/components/Modal/ModalImg.vue";
import ModalEdit from "@/components/Modal/ModalEdit.vue";
import ModalAdd from "@/components/Modal/ModalAdd.vue";

export default {
  name: "ListDraw",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  components: {
    Pagination,
    Breadcrumb,
    ModalImg,
    ModalEdit,
    ModalAdd
  },
  data: function () {
    return {
      breadcrumbOptions: {
        isShowRefresh: true,
        isShowStartSearch: true,
        isShowAdd: true,
        pageKey: "drawlist",
        buttonAddKey: "adddraw",
        refresh: () => this.Refresh(),
        startSearch: () => this.startSearch(),
        showModalAdd: () => this.showModalAdd()
      },
      editOptions: {
        type: "DrawListLottery",
        modalKey: "editdraw",
        onFileChange: (event) => this.onFileChangeedit(event),
        sendEditData: (event) => this.sendEditData(event),
      },
      addOptions: {
        type: "DrawListLottery",
        modalKey: "adddraw",
        onFileChange: (event) => this.onFileChange(event),
        sendCreateData: (event) => this.sendCreateData(event),
      },
      order: "drawDate",
      dir: "ASC",
      status: "",
      drawDate: "",
      onSearch: false,
      count: 0,
      PaginationData: {},

      imgPath: constant.imageUrl,
      selectedOne: "",
      errors: [],
      accessData: [],
      accessDataCustomer: [],
      companyData: [],
      listData: [],
      selectedID: [],
      createData: {
        IMAGESRC: "",
        drawNumber: "",
        drawDate: "",
        background: "",
      },
      editData: {
        IMAGESRC: "",
        drawDate: "",
        drawId: "",
      },
      showIMGData: "",
    };
  },
  mounted() {
    this.showData();
  },
  methods: {
    async Refresh() {
      this.count = 0;
      this.onSearch = false;
      this.order = "drawDate";
      this.dir = "ASC";
      this.status = "";
      this.drawDate = "";

      this.showData();
    },
    async startSearch() {
      this.count = 0;
      this.onSearch = true;
      this.showData();
    },
    PaginationReturnData(para_val) {
      this.PaginationData = para_val;

      this.showData();
    },
    async onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      console.log(files[0]);
      this.createData.background = files[0];
      var reader = new FileReader();

      reader.onload = (e) => {
        this.createData.IMAGESRC = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    },

    async onFileChangeedit(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.editData.background = files[0];
      var reader = new FileReader();

      reader.onload = (e) => {
        this.editData.IMAGESRC = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    },

    async showData() {
      try {
        let data = {
          start: this.PaginationData.offset,
          length: this.PaginationData.limit,
          // start:'0',
          // length:'10',
          order: this.order,
          dir: this.dir,
          status: this.status,
          drawDate: this.drawDate,
        };
        let getAPI = await serviceAPI.call_API(
          "get",
          "lotto/api/v1/listDraw",
          data,
          "auth"
        );
        console.log(getAPI);
        this.count = getAPI.data.recordsFiltered;
        this.listData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async showIMG(e) {
      $("#modal-image").modal("show");
      this.showIMGData = e.backgroundUrl;
    },
    async SwalselectStatusStaging(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You won't be able to ${para_status} this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${para_status} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatusStaging(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your is safe :)",
              "error"
            );
          }
        });
    },
    async SwalselectStatusOn(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You won't be able to ${para_status} this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${para_status} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatusOn(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your is safe :)",
              "error"
            );
          }
        });
    },
    async SwalselectStatusFinish(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You won't be able to ${para_status} this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${para_status} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatusFinish(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your is safe :)",
              "error"
            );
          }
        });
    },
    async selectStatusStaging(e) {
      try {
        let data = {
          drawId: e.id,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "lotto/api/v1/setDrawStatusToStaging",
          data,
          "auth"
        );

        this.Refresh();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async selectStatusOn(e) {
      try {
        let data = {
          drawId: e.id,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "lotto/api/v1/setDrawStatusToOnGoing",
          data,
          "auth"
        );

        this.Refresh();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async selectStatusFinish(e) {
      try {
        let data = {
          drawId: e.id,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "lotto/api/v1/setDrawStatusToFinish",
          data,
          "auth"
        );

        this.Refresh();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },

    async SwalDeleteUser(e) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.DeleteUser(e);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalCancel(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Cancel this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Cancel it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalClose(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Close this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Close it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalDelete(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async DeleteUser(e) {
      try {
        let data = { LOANID: e.id };
        let getAPI = await serviceAPI.call_API(
          "delete",
          "loanagreement/crudloanagreement",
          data,
          "auth"
        );
        let getData = getAPI.data.data;
        this.Refresh();
        this.$swal.fire("Deleted!", "Your user has been deleted.", "success");
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async getAccessData() {
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API(
          "get",
          "user/getaccessdata",
          data,
          "auth"
        );
        this.accessData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },

    async getAccessDataCustomer() {
      try {
        let data = {
          mode: "all",
          ID: "1",
          OFFSET: "0",
          RWS: "0",
        };
        let getAPI = await serviceAPI.call_API(
          "get",
          "customer/crudcustomer",
          data,
          "auth"
        );
        this.accessDataCustomer = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async sendCreateData(e) {
      try {
        e.preventDefault();
        let form = new FormData();
        form.append("drawNumber", this.createData.drawNumber);
        form.append("drawDate", this.createData.drawDate);
        form.append("background", this.createData.background);

        let getAPI = await serviceAPI.call_API_FORMDATA(
          "POST",
          "lotto/api/v1/addDraw",
          form
        );
        $("#new-member").modal("hide");
        this.createData.drawNumber = "";
        this.createData.drawDate = "";
        this.createData.IMAGESRC = "";
        this.createData.background = "";
        // serviceMain.showSuccessAlert(this,getData)
        let getData = getAPI.status.messageTH;
        serviceMain.showSuccessAlert(this, getData);
        this.Refresh();
      } catch (error) {
        $("#new-member").modal("hide");
        serviceMain.showErrorAlert(this, error);
      }
    },
    async checkForm(e) {
      e.preventDefault();

      if (
        this.createData.ID &&
        this.createData.BANKID &&
        this.createData.BANKNAME &&
        this.createData.BACCNO &&
        this.createData.BACCNA
      ) {
        this.sendCreateData();
        return;
      }

      this.errors = [];

      if (!this.createData.USERNAME) {
        this.errors.push("Username required.");
      }
      if (!this.createData.PASSWORD) {
        this.errors.push("Password required.");
      }
      if (!this.createData.FNAME) {
        this.errors.push("First Name required.");
      }
      if (!this.createData.LNAME) {
        this.errors.push("Last Name required.");
      }
      if (!this.createData.ROLE) {
        this.errors.push("Role required.");
      }
    },
    ckbox(para_id) {
      if (this.selectedID.includes(para_id)) {
        this.selectedID = this.selectedID.filter((e) => e !== para_id);
      } else {
        this.selectedID.push(para_id);
      }
    },
    async editForm(e) {
      this.editData = {
        background: e.backgroundUrl,
        drawDate: e.drawDate,
        drawId: e.id,
        IMAGESRC: e.backgroundUrl,
      };
      $("#modal-edit").modal("show");
    },

    async sendEditData(e) {
      try {
        e.preventDefault();
        let getImgURL = this.editData.background;
        console.log(getImgURL.name);
        // let response = await fetch(getImgURL);
        // let blob = await response.blob();
        // let file = new File([blob], 'image.jpg', {type: blob.type});
        // console.log(file)
        let form = new FormData();
        form.append("drawDate", this.editData.drawDate);
        form.append("drawId", this.editData.drawId);
        if (getImgURL.name) {
          form.append("background", getImgURL);
        }

        let getAPI = await serviceAPI.call_API_FORMDATA(
          "POST",
          "lotto/api/v1/editDraw",
          form
        );
        // console.log(getAPI)

        $("#modal-edit").modal("hide");
        this.editData.drawId = "";
        this.editData.drawDate = "";
        this.editData.IMAGESRC = "";
        this.editData.background = "";

        //    let getData = getAPI.status.messageTH;
        //     serviceMain.showSuccessAlert(this,getData)
        this.Refresh();
      } catch (error) {
        $("#modal-edit").modal("hide");
        serviceMain.showErrorAlert(this, error);
      }
    },
    uploadImageEdit(e) {
      let image = e.target.files[0];
      let FR = new FileReader();
      FR.onload = (ev) => {
        let getOringinalImg = ev.target.result;
        this.resizedataURLEdit(this, getOringinalImg, 100, 100);
      };
      FR.readAsDataURL(image);
    },
    resizedataURLEdit(_this, datas, wantedWidth, wantedHeight) {
      var img = document.createElement("img");
      img.onload = function () {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        var dataURI = canvas.toDataURL();
        console.log("dataURI:");
        console.log(dataURI);
      };
      img.src = datas;
    },
    viewpass() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    showModalAdd() {
      $('#modal-add').modal('show')
    }
  },
  computed: {
    getLocal: function () {
      return this.$store.getters["display/getLocal"];
    },
  },
};
</script>

<style scope>
.mymd table {
  border: 1px solid #ddd;
}
tr.border_bottom td {
  border-bottom: 1px solid #ddd;
}
.table-responsive {
  overflow-y: auto;
  max-height: 70vh;
}
.btn-action-cancel {
  background-color: rgb(31, 167, 13);
}
.btn-action-close {
  background-color: orange;
}
.btn-action-delete {
  background-color: rgb(226, 80, 80);
}
.btn-action-view {
  background-color: rgb(3, 117, 238);
}
.btn-action {
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  color: white;
  margin: 0px 5px;
}
.img-size {
  max-height: 100px;
}
 
</style>